
import CloudFun, { defineComponent, ref, Condition, Operator } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import { VueUploadItem } from "vue-upload-component";
import "@/global-components/ckeditor/styles.css";
import formatDate from 'xe-utils/toDateString'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '問與答',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '問與答類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '問與答類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true, minWidth: '33%' },
        { field: "Category.Name", title: "類別", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100,
        },
        { field: "Published", title: "已發布", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params: any) => {
          if (!params.sortings || params.sortings?.length === 0) {
            params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          }
          return model.dispatch("questionInfo/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("questionInfo/query") : undefined,
        save: model ? (params) => model.dispatch("questionInfo/save", params) : undefined,
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        { field: "CategoryId", title: "類別", span: 12, slots: { default: "column-category" } },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        { field: "Content", title: "內容", span: 24, slots: { default: "column-content" } },
      ],
      rules: {
        Title: [{ required: true }],
        CategoryId: [{ required: true }],
      }
    }

    const selectorOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value: any) => model?.dispatch("questionInfoCategory/find", value),
        query: (params: any) => {
          params.sortings.push({ column: 'Ordinal', order: 0 });
          return model?.dispatch("questionInfoCategory/query", params)
        },
      }
    };

    const onGridEdit = async(row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('questionInfo/find', row.Id) : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }

    const formSubmit = (row: any, submit: any) => {
      submit();
    }

    const initData = (row: any, callback: any) => {
      row.Ordinal = 0;
      row.Type = 2;
      row.Published = false;
      row.Content = { Content: '' };
      callback();
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    return {
      model,
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      initData,
      onGridEdit,
      formSubmit,
      selectorOptions,
      formatDate,
      classicEditor,
      editorConfig,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  }
})
